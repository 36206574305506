<template lang="html">
  <header id="header">
    <div class="header-content">

      <!-- Header menu -->
      <div class="header-menu">
        <b-navbar toggleable="lg" type="dark" variant="light">
          <div class="container oversized-container">
            <b-navbar-brand to="/">
              <img src="public/images/logo-colors.svg" alt="Impacto Cuantico">
            </b-navbar-brand>

            <div class="box-btns-out">
              <!-- <a class="btn-extra" @click="$refs['modal-search'].show()">
                <i class="fal fa-search"></i>
              </a> -->
              <router-link class="btn-extra" to="/contacto">
                <i class="fal fa-envelope"></i>
              </router-link>
              <router-link class="btn-extra" to="/cart">
                <i class="fal fa-shopping-cart"></i>
              </router-link>
            </div>

            <b-navbar-toggle target="nav-collapse">
              <i class="fal fa-bars"></i>
            </b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="ml-auto">
                <b-nav-item class="simple-item" to="/">Inicio</b-nav-item>
                <b-nav-item class="simple-item" to="/nosotros">Nosotros</b-nav-item>
                <b-nav-item class="simple-item" to="/niveles">Entrenamientos</b-nav-item>
                <b-nav-item class="simple-item" to="/blog">Distinciones</b-nav-item>

                <!-- <li class="nav-item icon-item">
                  <a class="nav-link" @click="$refs['modal-search'].show()">
                    <div>
                      <i class="fal fa-search icon"></i>
                    </div>
                  </a>
                </li> -->

                <b-nav-item class="icon-item" to="/contacto">
                  <span><i class="fal fa-envelope icon"></i></span>
                </b-nav-item>

                <b-nav-item class="icon-item" to="/cart">
                  <span><i class="fal fa-shopping-cart icon"></i></span>
                </b-nav-item>

                <!-- <b-nav-item-dropdown class="icon-item" right>
                  <template #button-content>
                    <span><i class="fal fa-user icon"></i></span>
                  </template>
                  <b-dropdown-item to="/login">Mi cuenta</b-dropdown-item>
                  <b-dropdown-item to="/registrarse">Registrarse</b-dropdown-item>
                </b-nav-item-dropdown> -->

                <!-- <b-nav-item class="btn-item" to="">Sistema</b-nav-item> -->
                <li class="nav-item btn-item">
                  <a class="nav-link" href="https://impactocuantico.com/admin" target="_blank">Sistema</a>
                </li>
              </b-navbar-nav>
            </b-collapse>
          </div>
        </b-navbar>
      </div>
      <!--  -->

      <!-- Header payments -->
      <div class="header-payments">
        <div class="b-1">
          <h6 class="txt">Inscríbete a los entrenamientos en linea y paga de forma segura</h6>

          <div class="imgs">
            <img src="public/images/shared/amex.png">
            <img src="public/images/shared/mastercard.png">
            <img src="public/images/shared/visa.png">
          </div>
        </div>

        <!-- <div class="b-2">
          <h6 class="txt">o con deposito o transferencia bancaria</h6>

          <div class="imgs">
            <img src="public/images/shared/i-card.png">
          </div>
        </div> -->
      </div>
      <!--  -->

      <!-- Modal Search -->
      <b-modal ref="modal-search" modal-class="modal-search-e" size="lg" title="" @hidden="form.keywords = null" hide-footer no-close-on-esc no-close-on-backdrop>
        <b-form @submit="onSubmitSearch">
          <b-form-group>
            <b-form-input
              v-model="form.keywords"
              type="text"
              placeholder="Buscar curso"
              size="lg"
              required
            ></b-form-input>
          </b-form-group>

          <b-button type="submit" class="btn-search"><i class="far fa-search"></i></b-button>
        </b-form>
      </b-modal>
      <!--  -->

    </div>
  </header>
</template>

<script>
export default {
  data(){
    return{
      form: {
        keywords: null,
      },
    }
  },

  methods: {
    onSubmitSearch(event) {
      event.preventDefault();
      this.$router.push({path: '/busqueda', query: {keywords:this.form.keywords}});
      this.$refs['modal-search'].hide();
    },
  },

  watch: {
    $route (to, from){
      // Ocultar el modal de busqueda cada vez que cambie la ruta
      this.$refs['modal-search'].hide();
    },
  },
}
</script>
