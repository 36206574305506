<template lang="html">
  <div id="detail-page-v2" v-if="!isloading">
    <section class="main-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-info">
            <h1 class="name">{{curso.titulo}}</h1>

            <div class="box-descr">
              <p>{{ curso.descripcion_corta }}</p>
            </div>

            <div class="box-countdown">
              <div class="box-title">
                <h6>{{curso.subtitulo}}</h6>
              </div>

              <countdown :time="curso.time_left">
                <template slot-scope="props">
                  <span class="box-digit">
                    <div class="b number">{{ props.days }}</div>
                    <div class="b text">Días</div>
                  </span>
                  <span class="box-digit">
                    <div class="b number">{{ props.hours }}</div>
                    <div class="b text">Horas</div>
                  </span>
                  <span class="box-digit">
                    <div class="b number">{{ props.minutes }}</div>
                    <div class="b text">Minutos</div>
                  </span>
                  <span class="box-digit">
                    <div class="b number">{{ props.seconds }}</div>
                    <div class="b text">Segundos</div>
                  </span>
                </template>
              </countdown>

            </div>

            <div class="box-buttons-s1">
              <a class="btn btn-s1 bg-csecondary" @click="$refs['modal-subscribe'].show();">Incríbete ahora</a>
            </div>
          </div>

          <div class="col-lg-6 col-video">
            <div class="video">
              <LazyYoutubeVideo :src="curso.url_youtube" />
              <!-- <iframe src="https://www.youtube.com/embed/mMLVr19wYZw?autoplay=0&mute=0&loop=1&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1&playlist=mMLVr19wYZw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="gallery-section">
      <div class="container">
        <h2 class="mb-2 mb-md-4 text-center title-s1">Galería de imágenes</h2>

        <swiper class="swiper" :options="galleryOptions">
          <swiper-slide>
            <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+curso.imageUrl+')' }" @click="openImageModal(curso.imageUrl)"></div>
          </swiper-slide>
          <swiper-slide>
            <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+curso.imageUrl+')' }" @click="openImageModal(curso.imageUrl)"></div>
          </swiper-slide>
          <swiper-slide>
            <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+curso.imageUrl+')' }" @click="openImageModal(curso.imageUrl)"></div>
          </swiper-slide>

          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </section>

    <section class="info-section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-options">
            <div class="row">
              <div class="col-md-4 col-btn">
                <a class="btn btn-s1 bg-cprimary">INSCRÍBETE AHORA</a>
              </div>

              <div class="col-md-4 col-btn">
                <a class="btn btn-s1 bg-csecondary">DESCARGAR PDF</a>
              </div>

              <div class="col-md-4 col-btn">
                <a class="btn btn-s1 bg-outline-cprimary">OPCIÓN</a>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-info">
            <h2 class="name">{{curso.titulo}}</h2>

            <div class="descr" v-html="curso.descripcion">

            </div>

            <h5 class="mt-2 price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(curso.costo) }} MXN</h5>

            <div class="box-buttons-s1">
              <a class="btn btn-s1 bg-csecondary" @click="$refs['modal-subscribe'].show();">Incríbete ahora</a>
              <a class="mt-2 btn btn-s1 bg-cprimary" :href="curso.documentUrl" target="_blank">Descargar PDF</a>
            </div>
          </div>

          <div class="col-lg-6 col-gallery">
            <img :src="curso.imageUrl">

            <h5 class="mt-4 price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(curso.costo) }} MXN</h5>
          </div>
        </div>
      </div>
    </section>

    <section class="testimonials-section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-title">
            <h2 class="title-s1">Testimonios</h2>
          </div>

          <div class="col-lg-6 col-testimonial" v-for="(t,indx) in curso.testimonies" :key="indx">
            <div class="box-testimonial">
              <div class="b-photo">
                <div class="placed-backg photo" v-bind:style="{ backgroundImage: 'url('+ t.imageUrl +')' }"></div>
              </div>

              <div class="b-text">
                <!-- NOTA: ¡NO DEBE TENER MUCHO TEXTO! -->
                <p>{{t.testimonio}} </p>

              </div>
              <div style="text-align: center;" v-if="t.url_youtube">

                <iframe width="350" height="200" :src="t.url_youtube" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <sweet-modal ref="loading" :blocking="true" :hide-close-button="true">
      <p>Cargando</p>
      <div class="fa-3x"><i class="fas fa-spinner fa-pulse"></i></div><br/>

    </sweet-modal>

    <b-modal modal-class="modal-subscribe" ref="modal-subscribe" hide-footer centered title="Suscríbete">
        <b-form @submit="onSubmit">

          <h6 class="lab">Selecciona una fecha disponible</h6>
          <div class="box-opts">
            <b-form-radio-group class="c-opts-group"
              v-model="formSubs.cursos_fechas_id"
              name="radio-sub-component">
              <b-form-radio :value="da.id" v-for="(da, indx) in curso.dates" :key="'date-'+indx">
                <div class="box">
                  {{da.date_custom}}
                </div>
              </b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="box-prices" v-if="curso.costo != null">
            <h6 class="lab">Total a pagar</h6>
            <h5 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(curso.costo) }} MXN</h5>
          </div>

          <div class="d-block pt-5">
            <b-form-checkbox
              class="mb-3"
              v-model="formSubs.check"
              name="checkbox-1"
              :value="true"
              :unchecked-value="null"
              required>
              Acepto los términos y condiciones
            </b-form-checkbox>

            <b-button type="submit" class="btn-s1 bg-csecondary w-100">INSCRIBIRME</b-button>

          </div>
        </b-form>
      </b-modal>

    <b-modal id="modal-gallery-s1" ref="modal-gallery" size="lg" centered hide-footer>
      <img class="img-modal" :src="modal.imgUrl">
    </b-modal>
  </div>
  <div id="detail-page-v2" v-else>
    <section class="main-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-info" style="text-align: center;">

                <p style="font-size: 1.5rem;">Cargando</p>
                <div style="font-size: 4.5rem;"><i class="fas fa-spinner fa-pulse"></i></div><br/>
            </div>
          </div>
        </div>
      </section>
  </div>
</template>

<script>
// https://www.npmjs.com/package/vue-lazy-youtube-video#demo
import LazyYoutubeVideo from 'vue-lazy-youtube-video';
import 'vue-lazy-youtube-video/dist/style.css';
export default {
  components: {
    LazyYoutubeVideo,
  },

  data() {
    return {
      formSubs: {
        cursos_id:null,
        cursos_fechas_id:null,
        check: null,
      },
      curso:{
        costo:null,
        url_youtube:'https://www.youtube.com/embed/mMLVr19wYZw',
        time_left:null,
        testimonies:[],
      },
      cursos: [
        /*{ imageUrl: 'public/images/pages/courses/course-1.jpg', title: 'Básico Cuántico' },
        { imageUrl: 'public/images/pages/courses/course-2.jpg', title: 'Avanzado Cuántico' },
        { imageUrl: 'public/images/pages/courses/course-3.jpg', title: 'Programa de Liderato' },*/
      ],

      modal: {
        imgUrl: null,
      },

      // == Carousel options //
      galleryOptions: {
        loop: false,
        slidesPerView: 3,
        spaceBetween: 20,

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        breakpoints: {
          992: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        }
      },

      coursesOptions: {
        loop: true,
        speed: 600,
        slidesPerView: 3,
        spaceBetween: 30,

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        breakpoints: {
          1600: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
        }
      },
      isloading:true,
      // == ==
    }
  },
  watch: {
      "$route.params.id" : function(v){
          this.id = this.$root._getURLID(this.$route.params.id);
          this.getData();
      },
  },
  methods: {
    openImageModal(imgUrl) {
      this.$refs['modal-gallery'].show();
      this.modal.imgUrl = imgUrl;
    },

    onSubmit(event) {
      event.preventDefault();
      if(this.formSubs.cursos_fechas_id == null){
          alert('Selecciona una fecha');
          return;
      }

        var cart = [];
        var encontrado = false;
        if(localStorage.cart){
            cart = JSON.parse(localStorage.getItem('cart'));

            for(var i = 0; i < cart.length ; i++)
            {
                if(cart[i].cursos_id == this.id )
                {
                    encontrado = true;
                    break;
                }
            }

            if(!encontrado)
            {
              cart.push({ id:this.id, cursos_fechas_id:this.formSubs.cursos_fechas_id });
            }
        }
        else
        {
            cart.push({ id:this.id, cursos_fechas_id:this.formSubs.cursos_fechas_id });
        }
        localStorage.setItem("cart",JSON.stringify(cart));
        this.$refs['modal-subscribe'].hide();
        this.$router.push('/cart');
    },
    getData: function(){
      this.isloading = true;
      axios.get(tools.url('/api/cursos/'+this.id)).then((response)=>{
          this.curso = response.data.curso;
          this.cursos = response.data.cursos;
          this.isloading = false;

      });
    }
  },
  mounted() {
    this.id = this.$root._getURLID(this.$route.params.id)
    this.getData();
  }
}
</script>
