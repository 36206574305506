<template lang="html">
  <div id="course-results-page">

    <section class="container oversized-container main-section">
      <div class="row">
        <div class="col-12 col-title">
          <h2 class="title-s1">Entrenamientos | {{ row.nombre }}</h2>
        </div>

        <div class="col-12 col-results">
          <div class="row">
            <div class="col-md-6 col-lg-4 col-course-sample-1" v-for="(c, cInx) in row.courses" :key="'cInx-'+cInx">
              <router-link class="box-course" :to="'/entrenamiento/'+c.id">
                <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+c.imageUrl+')' }"></div>
                <div class="box-title">
                  <h3>{{ c.titulo }}</h3>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      row: {
        nivel:null,
        courses:[],
      },
    }
  },
  methods:{
			getData(){
				axios.get(tools.url("/api/cursosindex/"+this.$route.params.nivels_id)).then((response)=>{

            this.row = response.data;
			    }).catch((error)=>{
			    });
			},
		},
		mounted(){
				this.getData();
		}
}
</script>
