<template lang="html">
  <footer id="footer">
    <section class="newsletter-section">
      <div class="container oversized-container">
        <div class="row align-items-center">
          <div class="col-lg-2 col-logo">
            <router-link to="/"><img src="public/images/logo-colors.svg"></router-link>
          </div>

          <div class="col-lg col-newsletter">
            <div class="row align-items-center">
              <div class="col-lg col-text">
                <h5>¡Suscríbete al Newsletter!</h5>
              </div>

              <div class="col-lg col-form">
                <b-form class="row no-gutters" @submit="onSubmit">
                  <b-form-group class="col">
                    <b-form-input
                      v-model="form.email"
                      type="email"
                      placeholder="Escribe tu correo"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-button type="submit" class="col btn-snewsletter">Suscribirse</b-button>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  	<section class="footer-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-5">
            <div class="row">
              <div class="col-sm-6 col-lg-5 col-footer">
                <h5>General</h5>

                <p class="mt-lg-1"><router-link class="link-footer" to="/">Inicio</router-link></p>
                <p class="f-w-300"><router-link class="link-footer" to="/cursos">Todos los entrenamientos</router-link></p>
                <p class="mt-lg-1"><router-link class="link-footer" to="/blog">Distinciones</router-link></p>
								<p class="mt-lg-1"><router-link class="link-footer" to="/contacto">Contacto</router-link></p>
              </div>

              <div class="col-sm-6 col-lg-7 col-footer">
                <h5>Links de ayuda</h5>

                <p class="mt-lg-1"><router-link class="link-footer" to="/preguntas-frecuentes">Preguntas frecuentes</router-link></p>
								<p class="mt-lg-1"><router-link class="link-footer txt-sm" to="/terminos-y-condiciones">Términos y condiciones</router-link></p>
                <p class="mt-lg-1"><router-link class="link-footer txt-sm" to="/aviso-de-privacidad">Aviso de privacidad</router-link></p>
              </div>

              <!-- <div class="col-lg-4 col-footer">
                <h5>INFORMACIÓN</h5>

                <p class="mt-lg-1"><router-link class="link-footer txt-sm" to="/terminos-y-condiciones-de-uso">Términos y condiciones de uso</router-link></p>
                <p class="mt-lg-1"><router-link class="link-footer txt-sm" to="/aviso-de-privacidad">Aviso de privacidad</router-link></p>
              </div> -->
            </div>
          </div>

          <div class="col-lg-4 col-footer col-copyright align-self-center">
            <!-- <h5>Dirección</h5> -->

            <p class="p-sm p-icon i-map">
              <strong>GUADALAJARA</strong><br />
              La Paz #1413, Col. Americana, 44170<br />
              Guadalajara, Jal., México
            </p>

            <p class="mt-3 p-sm p-icon i-map">
              <strong>MONTERREY</strong><br />
              Venustiano Carranza Sur #745
              Local 53, Centro, 64000<br />
              Monterrey, N.L., México
            </p>
          </div>

          <div class="col-lg-3 col-footer col-copyright align-self-center">
            <p class="mb-3">
              <!-- <router-link class="t-150 btn-network" to="/contacto">
                <span><i class="fal fa-envelope"></i></span>
              </router-link> -->
              <a class="t-150 btn-network" target="_blank" href="https://www.instagram.com/impactocuantico">
                <span><i class="fab fa-instagram"></i></span>
              </a>
              <!-- <a class="t-150 btn-network" target="_blank" href="#">
                <span><i class="fab fa-tiktok"></i></span>
              </a> -->
							<a class="t-150 btn-network" target="_blank" href="https://www.facebook.com/impactocuantico">
								<span><i class="fab fa-facebook-f"></i></span>
							</a>
              <!-- <a class="t-150 btn-network" target="_blank" href="#">
                <span><i class="fab fa-linkedin-in"></i></span>
              </a> -->
            </p>

            <p class="p-sm p-icon i-letter">
              contacto@impactocuantico.com
            </p>
            <!-- <p class="mt-3 p-sm p-icon i-phone">
              +55 333-964-9438
            </p> -->

            <p class="mt-2 copy-text">
              <i>Copyright {{ currentYear }} Impacto Cuántico</i>
            </p>
          </div>
  			</div>
      </div>
  	</section>
  </footer>
</template>

<script>
export default {
  data(){
    return{
      currentYear: 0,

      form: {
        email: null
      },
    }
  },

  methods:{
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      });
    },

    onSubmit(evt) {
      evt.preventDefault();

        axios.post(tools.url("/api/newsletter"), this.form).then((response)=>{
          alert('Infomación guardada correctamente');
          this.form = {
            name: null,
            email: null,
            msg: null,
            check: null
          };
        }).catch( (error)=> {

        });

    },
  },

  beforeMount() {
    this.currentYear = new Date().getFullYear();
  }
}
</script>
