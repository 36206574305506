<template lang="html">
  <div id="detail-page-s2">

    <section class="main-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-6 col-xl-5 col-image">
            <div class="image-container">
              <swiper class="swiper" :options="galleryOptions">
                <swiper-slide v-for="(g, gpInx) in 1" :key="'gpInx-'+gpInx">
                  <div class="placed-backg swiper-image" v-bind:style="{ backgroundImage: 'url('+ content.imageUrl +')' }">
                  </div>
                </swiper-slide>

                <div class="swiper-pagination" slot="pagination"></div>
              </swiper>
            </div>
          </div>

          <div class="col-lg-6 offset-xl-1 col-info">
            <h1 class="text-center text-lg-left title-s1">Nosotros</h1>

            <div class="box-descr" v-html="content.body"></div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {

      // == Carousel options //
      galleryOptions: {
        effect: 'fade',
        speed: 700,

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
      },
      // == ==
      content:{
        content:null,
        imageUrl:null
      }
    }
  },
  methods: {
    getContent: function(){
      axios.get(tools.url('/api/nosotros')).then((response)=>{
        this.content = response.data;;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
    this.getContent();
  }
}
</script>
