<template lang="html">
  <div id="course-results-page">

    <section class="container oversized-container main-section">
      <div class="row">
        <div class="col-12 col-title">
          <h2 class="title-s1">
            Búsqueda de entrenamientos<br />
            <small>{{ $route.query.keywords }}</small>
          </h2>
        </div>

        <div class="col-12 col-results">
          <div class="row">
            <div class="col-md-6 col-lg-4 col-course-sample-1" v-for="(c, cInx) in courses" :key="'cInx-'+cInx">
              <router-link class="box-course" to="/cursos/1">
                <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+c.imageUrl+')' }"></div>
                <div class="box-title">
                  <h3>{{ c.title }}</h3>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      courses: [
        { imageUrl: 'public/images/pages/courses/course-1.jpg', title: 'Básico Cuántico' },
        { imageUrl: 'public/images/pages/courses/course-2.jpg', title: 'Avanzado Cuántico' },
        { imageUrl: 'public/images/pages/courses/course-3.jpg', title: 'Programa de Liderato' },

        { imageUrl: 'public/images/pages/courses/course-3.jpg', title: 'Programa de Liderato' },
        { imageUrl: 'public/images/pages/courses/course-1.jpg', title: 'Básico Cuántico' },
        { imageUrl: 'public/images/pages/courses/course-2.jpg', title: 'Avanzado Cuántico' },
        { imageUrl: 'public/images/pages/courses/course-1.jpg', title: 'Básico Cuántico' },
        { imageUrl: 'public/images/pages/courses/course-2.jpg', title: 'Avanzado Cuántico' },
        { imageUrl: 'public/images/pages/courses/course-3.jpg', title: 'Programa de Liderato' },
      ],
    }
  }
}
</script>
