<template lang="html">
    <div id="article-page">
  
      <div class="container">
  
        <div class="row">
          <!-- Artículo actual -->
          <div class="col-lg-6 col-article">
            <div class="mb-3 box-image">
              <img :src="row.imageUrlTwo" width="100%">
            </div>
            
          </div>
          <!--  -->
  
          <!-- Artículos -->
          <div class="col-lg-6 col-blog">
            <div class="row">
              <div class="col-12 col-article-sample-2 sample-2" v-html="row.descripcion" style="background-color: white;">
                
              </div>
            </div>
          </div>
          <!--  -->
        </div>
      </div>
  
    </div>
  </template>
  
  <script>
  export default {
    data(){
      return{
        row: {
         
        },
        
        id: null,
      }
    },
  
      watch: {
          "$route.params.id" : function(v){
              this.id = this.$root._getURLID(this.$route.params.id);
              this.getRwo();
          },
      },
  
    methods: {
      getRwo: function(){
        axios.get(tools.url('/api/levels/'+this.id)).then((response)=>{
            this.row = response.data;
        });
      }
    },
  
    mounted(){
      this.id = this.$root._getURLID(this.$route.params.id);
      this.getRwo();
    }
  }
  </script>
  