<template lang="html">
    <div id="course-results-page">
  
      <section class="container oversized-container main-section">
        <div class="row">
          <div class="col-12 col-title">
            <h2 class="title-s1">Entrenamientos</h2>
          </div>
  
          <div class="col-12 col-results">
            <div class="row">
              <div class="col-md-6 col-lg-4 col-course-sample-1" v-for="(c, cInx) in levels" :key="'cInx-'+cInx">
                <router-link class="box-course" :to="'/niveles/'+c.id">
                  <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+c.imageUrl+')' }"></div>
                  <div class="box-title">
                    <h3>{{ c.nombre }}</h3>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        levels: [
       ],
      }
    },
    methods:{
              getData(){
                  axios.get(tools.url("/api/levels")).then((response)=>{
              this.levels = response.data;
                  }).catch((error)=>{
                  });
              },
          },
          mounted(){
                  this.getData();
          }
  }
  </script>
  