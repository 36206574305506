<template lang="html">
  <div id="contact-page">

    <section class="container main-section">
      <div class="row align-items-center">
        <div class="col-12 col-title">
          <h1 class="title-s1">Contacto</h1>
        </div>

        <div class="col-lg-6 col-info">
          <h5 class="title">¿Tienes preguntas?<br />Envíanos un mensaje o comunicate con nosotros.</h5>

          <h5 class="mt-3 subtitle">Dirección</h5>
          <p>
            <strong>GUADALAJARA</strong><br />
            La Paz #1413, Col. Americana, 44170
            Guadalajara, Jal., México
          </p>
          <p class="mt-2">
            <strong>MONTERREY</strong><br />
            Venustiano Carranza Sur #745
            Local 53, Centro, 64000<br />
            Monterrey, N.L., México
          </p>

          <h5 class="mt-3 subtitle">Teléfono</h5>
          <p>
            Tel. +55 333-964-9438
          </p>

          <h5 class="mt-3 subtitle">WhatsApp</h5>
          <p>
            <strong>GDL:</strong> <a class="f-w-500" target="_blank" href="https://wa.me/5213346377717"><i class="fab fa-whatsapp"></i> 33 4637 7717</a>
          </p>
          <p class="mt-1">
            <strong>MTY:</strong> <a class="f-w-500" target="_blank" href="https://wa.me/5218139722871"><i class="fab fa-whatsapp"></i> 81 3972 2871</a>
          </p>

          <h5 class="mt-3 subtitle">Correo</h5>
          <p>
            contacto@impactocuantico.com
          </p>

          <h5 class="mt-3 subtitle">Síguenos</h5>
          <p>
            <a class="btn-network mr-1" target="_blank" href="https://www.instagram.com/impactocuantico/"><i class="fab fa-instagram"></i></a>
           <!--  <a class="btn-network ml-1" href="#"><i class="fab fa-twitter"></i></a> -->
            <a class="btn-network ml-1" target="_blank" href="https://www.facebook.com/impactocuantico"><i class="fab fa-facebook-f"></i></a>
            <!-- <a class="btn-network ml-1" href="#"><i class="fab fa-linkedin-in"></i></a> -->
          </p>
        </div>

        <div class="col-lg-6 col-form">
          <b-form @submit="onSubmit">
            <div class="row">
              <b-form-group class="cus-f-group-1 col-12" label="Nombre completo">
                <b-form-input type="text" v-model="form.name" required placeholder=""></b-form-input>
              </b-form-group>

              <b-form-group class="cus-f-group-1 col-12" label="Correo electrónico">
                <b-form-input type="email" v-model="form.email" required placeholder=""></b-form-input>
              </b-form-group>

              <b-form-group class="cus-f-group-1 col-12" label="Teléfono">
                <b-form-input type="text" v-model="form.phone" minlength="10" maxlength="10" required placeholder=""></b-form-input>
              </b-form-group>

              <b-form-group class="cus-f-group-1 col-12" label="Mensaje">
                <b-form-textarea
                  v-model="form.msg"
                  placeholder=""
                  rows="5"
                  max-rows="5"
                ></b-form-textarea>
              </b-form-group>

              <div class="col-12 text-right">
                <b-button type="submit" class="btn-s1 bg-csecondary">ENVIAR</b-button>
              </div>
            </div>
          </b-form>
        </div>

        <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
          <div v-html="modal.msg"></div>
          <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
            <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close()">Aceptar</b-button>
          </div>
        </sweet-modal>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        name: null,
        email:null,
        phone:null,
        msg:null
      },

      modal:{
        msg:'',
        icon:'',
        block:false
      },
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },

    onSubmit(evt) {
      evt.preventDefault();

      var params = this.form;
      var apiURL = tools.url("/api/contact");

      axios.post( apiURL,params )
      .then( (response) => {
        // alert(response.data.response);
        this.makeToast('success', response.data.response, 'Mensaje enviado');
        // alert(response.data.response);

        for (let prop in this.form) {
          this.form[prop] = '';
        }
      })
      .catch( (error) => {
        // console.log(error.response.data);
        let errors = error.response.data;

        for (let prop in errors) {
          // console.log(errors[prop]);
          this.makeToast('danger', errors[prop], '¡Error!');
        }
      });
    },
  },
}
</script>
