<template lang="html">
  <div id="article-results-page">

    <section class="container oversized-container main-section">
      <div class="row">
        <div class="col-12 col-title">
          <h2 class="title-s1">Distinciones</h2>
        </div>

        <div class="col-12 col-results">
          <div class="row">
            <div class="col-md-6 col-xl-4 col-article-sample-1" v-for="(b, bInx) in blogs" :key="'bInx-'+bInx">
              <router-link class="box-article" :to="'/blog/'+b.id">
                <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }"></div>
                <div class="box-text">
                  <!-- <h6 class="category">AVISOS IMPORTANTES</h6> -->
                  <h5 class="title">{{ b.title }}</h5>
                  <h6 class="date">{{ b.created}}</h6>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      blogs: [
     ],
    }
  },
  methods:{
			getHome(){
				axios.get(tools.url("/api/blogs")).then((response)=>{
            this.blogs = response.data;
			    }).catch((error)=>{
			    });
			},
		},
		mounted(){
				this.getHome();
		}
}
</script>
