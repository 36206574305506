<template lang="html">
  <div id="home-page">

    <section class="container-fluid banner-section">
      <div v-swiper="bannersOptions" class="swiper swiper-desktop" v-if="banners.pc.length" :instanceName="'carrusel-bann1'" :loadtheme="false">
        <div class="swiper-wrapper">
          <div class="swiper-slide"  v-for="(banner, bdInx) in banners.pc" :key="'bdInx-'+bdInx">
            <a class="d-block placed-backg" v-bind:style="{ backgroundImage: 'url('+banner.imageUrl+')' }" :href="banner.url">
              <img src="public/images/pages/home/banner.png">
            </a>
          </div>
        </div>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </div>

      <div v-swiper="bannersOptions" class="swiper swiper-mobile" v-if="banners.movil.length" :instanceName="'carrusel-bann2'" :loadtheme="false">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(banner, bmInx) in banners.movil" :key="'bmInx-'+bmInx">
            <a class="d-block placed-backg" v-bind:style="{ backgroundImage: 'url('+banner.imageUrl+')' }" :href="banner.url" >
              <img src="public/images/pages/home/banner-m.png">
            </a>
          </div>
        </div>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </div>
    </section>

    <section class="about-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-5 col-title">
            <h2>¿Por qué somos la mejor opción?</h2>
          </div>

          <div class="col-lg-5 col-text">
            <p>Contamos con certificaciones internacionales y miles de horas de experiencia en capacitación y entrenamiento .</p>
          </div>
        </div>
      </div>
    </section>

    <section class="container oversized-container courses-section">
      <div class="row">
        <div class="col-12 col-title">
          <h2 class="title-s1">Visiones</h2>
        </div>

        <div class="col-12 col-courses">
          <swiper class="swiper" :options="coursesOptions">
            <swiper-slide v-for="(c, cInx) in courses" :key="'cInx-'+cInx">
              <div class="col-12 px-0 col-course-sample-1">
                <router-link class="box-course" :to="'/entrenamiento/'+c.id">
                  <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+c.imageUrl+')' }"></div>
                  <div class="box-title">
                    <h3>{{ c.titulo }}</h3>
                  </div>
                </router-link>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>

      </div>
    </section>

    <section class="blog-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-12 col-blog">
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide v-for="(b, bInx) in blogs" :key="'bInx-'+bInx">
                <div class="col-12 px-0 col-article-sample-1">
                  <router-link class="box-article" :to="'/blog/'+b.id">
                    <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }"></div>
                    <div class="box-text">
                      <!-- <h6 class="category">AVISOS IMPORTANTES</h6> -->
                      <h5 class="title">{{ b.title }}</h5>
                      <h6 class="date">{{ b.created }}</h6>
                    </div>
                  </router-link>
                </div>
              </swiper-slide>

              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      banners: {
        /*pc: [
          { imageUrl: 'public/images/pages/home/banner-1.jpg', url: '' },
        ],
        movil:[
          { imageUrl: 'public/images/pages/home/banner-1-m.jpg', url: '' },
        ]*/
      },

      courses: [
        /*{ imageUrl: 'public/images/pages/courses/course-1.jpg', title: 'Básico Cuántico' },
        { imageUrl: 'public/images/pages/courses/course-2.jpg', title: 'Avanzado Cuántico' },
        { imageUrl: 'public/images/pages/courses/course-3.jpg', title: 'Programa de Liderato' },*/
      ],

      blogs: [
        /*{ imageUrl: 'public/images/pages/blog/article-1.jpg', title: 'Próximo programa Guadalajara, Hotel Hilton Expo. 17 de Marzo 2023.' },
        { imageUrl: 'public/images/pages/blog/article-2.jpg', title: 'Programa COACHING Norte. Holiday Inn, San Pedro Garza. 19 de Marzo de 2023.' },
        { imageUrl: 'public/images/pages/blog/article-3.jpg', title: 'Programa de Liderazgo Centro. World Trade Center Napoles. Ciudad de México 19 de Marzo de 2023.' },*/
      ],

      // == Carousels options ==
      bannersOptions: {
        slidesPerView: 1,
        loop: true,
        speed: 750,

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },

      coursesOptions: {
        loop: true,
        speed: 600,
        slidesPerView: 3,
        spaceBetween: 30,

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        breakpoints: {
          1600: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
        }
      },

      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        breakpoints: {
          1300: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      }
      // == ==
    }
  },
  methods:{
			getHome(){
				axios.get(tools.url("/api/home")).then((response)=>{
            this.banners = response.data.banners;
            this.blogs = response.data.blogs;
            this.courses = response.data.courses;
			    }).catch((error)=>{
			    });
			},
		},
		mounted(){
				this.getHome();
		}
	}
</script>

